import React from "react";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../common/Breadcrumb";
import AuctionWrap from "./AuctionWrap";
import { useGetAuctionsQuery } from "../../../services/auctions";

function LiveAuction({ match: { params } }) {
  const { type } = params;
  const { t } = useTranslation();
  const auctionType = t(type);
  const { isLoading, data } = useGetAuctionsQuery({ type });
  if (isLoading) return <div>Loading...</div>;
  const { data: auctions } = data || [];
  return (
    <>
      <Breadcrumb
        pageName={`${t("auction")} ${auctionType}`}
        pageTitle={`${t("auction")} ${auctionType}`}
      />
      <AuctionWrap auctions={auctions} />
    </>
  );
}

export default LiveAuction;
