import React, { useEffect, useState } from "react";
import AuctionDetailsInfo from "./AuctionDetailsInfo";
import AuctionDetailsTab from "./AuctionDetailsTab";
import {
  useAddBidMutation,
  useGetHighestBidQuery,
} from "../../../services/auctions";

function AuctionDetailsWrap({ auction, isActiveAuction, isUpcomingAuction }) {
  const { mutate: PlaceABid } = useAddBidMutation();
  const [amount, setAmount] = useState(auction.HighestBidValue);
  const [allowedBidPrice, setAllowedBidPrice] = useState(
    auction.AllowedBiddingPrice
  );

  const { data, isLoading } = useGetHighestBidQuery(
    auction.Id,
    amount,
    isActiveAuction
  );

  const handlePlaceBid = () => {
    const newBidAmount = auction.HighestBidValue + auction.AllowedBiddingPrice;
    PlaceABid({ bid: newBidAmount, id: auction.Id });
  };
  const AllowedBiddingPriceV2 = data?.data?.AllowedBiddingPrice;
  const highestAmount = data?.data?.bid?.Amount || 0;

  useEffect(() => {
    if (highestAmount !== amount && !isLoading) {
      setAmount(highestAmount);
    }
  }, [highestAmount]);

  useEffect(() => {
    if (AllowedBiddingPriceV2 !== allowedBidPrice) {
      setAllowedBidPrice(AllowedBiddingPriceV2);
    }
  }, [AllowedBiddingPriceV2]);

  return (
    <>
      <div className="auction-details-section pt-120 pb-120">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-top"
        />
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-bottom"
        />

        <div className="container">
          <AuctionDetailsInfo
            isActiveAuction={isActiveAuction}
            isUpcomingAuction={isUpcomingAuction}
            auction={auction}
            onPlaceBid={handlePlaceBid}
            amount={amount}
            isLoading={isLoading}
            allowedBidPrice={allowedBidPrice}
            highestAmount={highestAmount}
          />
          <AuctionDetailsTab
            isActiveAuction={isActiveAuction}
            isUpcomingAuction={isUpcomingAuction}
            auction={auction}
            onPlaceBid={handlePlaceBid}
            amount={amount}
            isLoading={isLoading}
            allowedBidPrice={allowedBidPrice}
            highestAmount={highestAmount}
          />
        </div>
      </div>
    </>
  );
}

export default AuctionDetailsWrap;
