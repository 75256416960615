import React from "react";
import { useTranslation } from "react-i18next";
import Welcome from "../../../assets/images/pigeons/welcom.png";
const WelcomeScreen = () => {
  const { t } = useTranslation();
  return (
    <div className="container my-5">
      <div className="row d-flex justify-content-center">
        <div className="col-xl-6 col-lg-8 col-md-10">
          <div
            className="form-wrapper wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay=".2s"
          >
            <div className="form-title">
              <h3>{t("register.welcoming")}</h3>
              <p>{t("register.info")} </p>
              <img className="w-100" src={Welcome} alt="Welcome" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WelcomeScreen;
