import React, { useEffect, useRef, useState } from "react";

function Counter({ time }) {
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");
  let interval = useRef();

  const startTimer = (time) => {
    const countdownDate = new Date(time);
    interval.current = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setTimerDays(days.toString().padStart(2, "0"));
        setTimerHours(hours.toString().padStart(2, "0"));
        setTimerMinutes(minutes.toString().padStart(2, "0"));
        setTimerSeconds(seconds.toString().padStart(2, "0"));
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer(time);

    return () => {
      clearInterval(interval.current);
    };
  }, [time]);

  return (
    <>
      {timerDays !== "00" && (
        <>
          <span id="days1">{timerDays}</span>D :&nbsp;
        </>
      )}
      <span id="hours1">{timerHours}</span>H :&nbsp;
      <span id="minutes1">{timerMinutes}</span>M :&nbsp;
      <span id="seconds1">{timerSeconds}</span>S
    </>
  );
}

export default Counter;
