import React, { useState } from "react";
import Pagination from "../../common/Pagination";
import BlogSingleItem from "./BlogSingleItem";
import { useBlogsQuery } from "../../../services/blogs";
import CardSkeleton from "../../common/CardSkeleton";

function BlogWrap() {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading } = useBlogsQuery({ page: currentPage });

  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <>
      <div className="blog-section pt-120 pb-120">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-top"
        />
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-bottom"
        />
        <div className="container">
          <div className="row d-flex justify-content-center g-4 mb-60">
            {isLoading ? (
              <>
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
              </>
            ) : (
              data.BlogPosts.map(
                ({ Id, Title, CreatedOn, PictureModel: { ImageUrl } }) => (
                  <div
                    key={Id}
                    className="col-xl-4 col-lg-6 col-md-6 col-sm-10"
                  >
                    <BlogSingleItem
                      date={CreatedOn}
                      image={ImageUrl}
                      blogContent={Title}
                      authorName="Darwish Auction"
                    />
                  </div>
                )
              )
            )}
          </div>
          <Pagination
            totalPages={data?.PagingFilteringContext.TotalPages}
            onPageChange={onPageChange}
            currentPage={currentPage}
          />
        </div>
      </div>
    </>
  );
}

export default BlogWrap;
