import React, { useEffect, useReducer, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TopbarHeader from "./TopbarHeader";

function Header() {
  const [search, setSearch] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const { t } = useTranslation();
  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = () => {
    const header = document.querySelector(".header-area");
    const scrollTop = window.scrollY;
    scrollTop >= 20
      ? header?.classList.add("sticky")
      : header?.classList
      ? header?.classList.remove("sticky")
      : header?.classList.add("sticky");
  };

  /*---------menu button event----------*/
  const handleSidbarMenu = () => {
    if (sidebar === false || sidebar === 0) {
      setSidebar(1);
    } else {
      setSidebar(false);
    }
  };

  /*---------add event scroll top----------*/
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const searchFullScreen = () => {
    if (search === false || search === 0) {
      setSearch(1);
      console.log(search);
    } else {
      setSearch(false);
    }
  };

  /*---------Using reducer mange the active or inactive menu----------*/
  const initialState = { activeMenu: "" };
  const [state, dispatch] = useReducer(reducer, initialState);

  function reducer(state, action) {
    switch (action.type) {
      case "home":
        return { activeMenu: "home" };
      case "pages":
        return { activeMenu: "pages" };
      case "blog":
        return { activeMenu: "blog" };
      case "auctions":
        return { activeMenu: "auctions" };
      case "itwork":
        return { activeMenu: "itwork" };
      case "about":
        return { activeMenu: "about" };
      case "contact":
        return { activeMenu: "contact" };
      default:
        return { activeMenu: "" };
    }
  }

  return (
    <>
      <TopbarHeader />
      <div className={search === 1 ? "mobile-search slide" : "mobile-search"}>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-11">
              <label>What are you looking for?</label>
              <input
                type="text"
                placeholder="Search Products, Category, Brand"
              />
            </div>
            <div className="col-1 d-flex justify-content-end align-items-center">
              <div className="search-cross-btn " onClick={searchFullScreen}>
                {/* <i class="bi bi-search me-4"></i> */}
                <i className="bi bi-x" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <header className="header-area style-1">
        <div className="header-logo">
          <Link to={`${process.env.PUBLIC_URL}/`} onClick={scrollTop}>
            <img
              style={{ width: "50px" }}
              alt="logo"
              src={process.env.PUBLIC_URL + "/logo-192.png"}
            />
          </Link>
        </div>
        <div className={sidebar === 1 ? "main-menu show-menu" : "main-menu"}>
          <div className="mobile-logo-area d-lg-none d-flex justify-content-between align-items-center">
            <div className="mobile-logo-wrap ">
              <Link to={"/"}>
                <img
                  style={{ width: "50px" }}
                  alt="logo"
                  src={process.env.PUBLIC_URL + "/logo-192.png"}
                />
              </Link>
            </div>
            <div className="menu-close-btn" onClick={handleSidbarMenu}>
              <i className="bi bi-x-lg" />
            </div>
          </div>
          <ul className="menu-list">
            <li onClick={() => dispatch({ type: "home" })}>
              <Link
                to={`${process.env.PUBLIC_URL}/`}
                onClick={scrollTop}
                className={`${state.activeMenu === "home" ? "active" : ""} `}
              >
                {t("home")}
              </Link>
            </li>
            <li onClick={() => dispatch({ type: "about" })}>
              <Link
                to={`${process.env.PUBLIC_URL}/about`}
                onClick={scrollTop}
                className={`${state.activeMenu === "about" ? "active" : ""} `}
              >
                {t("about")}
              </Link>
            </li>
            {/* <li onClick={() => dispatch({ type: "itwork" })}>
              <Link
                to={`${process.env.PUBLIC_URL}/how-works`}
                onClick={scrollTop}
                className={`${state.activeMenu === "itwork" ? "active" : ""} `}
              >
                {t("how_it_work")}
              </Link>
            </li> */}
            <li onClick={() => dispatch({ type: "auctions" })}>
              <Link
                to={`${process.env.PUBLIC_URL}/auctions/current`}
                onClick={scrollTop}
                className={`${
                  state.activeMenu === "auctions" ? "active" : ""
                } `}
              >
                {t("auctions")}
              </Link>
            </li>

            <li onClick={() => dispatch({ type: "blog" })}>
              <Link
                to={`${process.env.PUBLIC_URL}/blog`}
                onClick={scrollTop}
                className={`${state.activeMenu === "blog" ? "active" : ""} `}
              >
                {t("our_news_feed")}
              </Link>
            </li>

            <li>
              <Link to={`/login`} onClick={scrollTop}>
                {t("my_account")}
              </Link>
            </li>

            {/* 
            <li onClick={() => dispatch({ type: "contact" })}>
              <Link
                to={`${process.env.PUBLIC_URL}/contact`}
                onClick={scrollTop}
                className={`${state.activeMenu === "contact" ? "active" : ""} `}
              >
                Contact
              </Link>
            </li> */}
          </ul>
          {/* mobile-search-area */}
        </div>
        <div className="nav-right d-flex align-items-center">
          <div className="hotline d-xxl-flex d-none">
            <div className="hotline-icon">
              <img
                alt="images"
                src={process.env.PUBLIC_URL + "/images/icons/header-phone.svg"}
              />
            </div>
            <div className="hotline-info">
              <span>Click To Call</span>
              <h6 style={{ dir: "ltr", textAlign: "left" }}>
                <a
                  href="tel:+201023327838"
                  style={{ dir: "ltr", textAlign: "left" }}
                >
                  +2 010 233 278 38
                </a>
              </h6>
            </div>
          </div>

          <div className="eg-btn btn--primary header-btn">
            <Link to={`/login`} onClick={scrollTop}>
              {t("my_account")}
            </Link>
          </div>
          <div
            className="mobile-menu-btn d-lg-none d-block"
            onClick={handleSidbarMenu}
          >
            <i className="bx bx-menu" />
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
