import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import LoginWrap from "./LoginWrap";
import { isAuth } from "../../../utils";
import { Redirect } from "react-router-dom";

function Login() {
  return isAuth() ? (
    <Redirect to="/dashboard" />
  ) : (
    <>
      <Breadcrumb pageName="Log In" pageTitle="Log In" />
      <LoginWrap />
    </>
  );
}

export default Login;
