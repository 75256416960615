import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useBlogsQuery } from "../../../services/blogs";
import { formatDateMDY } from "../../../utils";

function RecentNewsHome() {
  const { t } = useTranslation();
  const { data, isLoading } = useBlogsQuery({ pageSize: 2 });
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  if (isLoading) return <div>Loading...</div>;
  const { data: news } = data || [];
  return (
    <>
      <div className="recent-news-section pt-50 pb-120">
        <img
          alt="recentImage"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg"
        />
        <img
          alt="recentImage"
          src={process.env.PUBLIC_URL + "/images/icons/dot-circle.svg"}
          className="dot-circle"
        />
        {news?.BlogPosts.length && (
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
                <div className="section-title1">
                  <h2>{t("recent_news")}</h2>
                  <p className="mb-0">{t("recent_news_summarize")}</p>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center g-4">
              {news?.BlogPosts?.map(
                ({ Id, CreatedOn, Title, PictureModel, SeName }) => (
                  <div key={Id} className="col-md-6 col-sm-10">
                    <div
                      className="single-blog-style1 wow animate fadeInDown"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.4s"
                    >
                      <div className="blog-img">
                        <Link to={"#"} className="blog-date">
                          <i className="bi bi-calendar-check" />
                          {formatDateMDY(CreatedOn)}
                        </Link>
                        <img
                          alt="recentImage"
                          src={
                            process.env.REACT_APP_API_URL +
                            PictureModel?.ImageUrl
                          }
                        />
                      </div>
                      <div className="blog-content">
                        <h5>
                          <Link
                            to={`${process.env.PUBLIC_URL}/blog-details/${SeName}`}
                            onClick={scrollTop}
                          >
                            {Title}
                          </Link>
                        </h5>
                        <div className="blog-meta">
                          <div className="author">
                            <img
                              alt="recentImage"
                              src={
                                process.env.PUBLIC_URL +
                                "/images/blog/author1.png"
                              }
                            />
                            <Link to={"#"} className="author-name">
                              {t("darwish")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default RecentNewsHome;
