import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import HowItWorkContent from "./HowItWorkContent";
import WhyChooseUs from "./WhyChooseUs";

function HowItWork() {
  return (
    <>
      <Breadcrumb pageName="How It Works" pageTitle="How It Works" />
      <HowItWorkContent />
      <WhyChooseUs />
    </>
  );
}

export default HowItWork;
