import { useQuery } from "react-query";
import { fetchProductDetails, fetchBlogs } from "../apis";
import { useTranslation } from "react-i18next";

export const useBlogsQuery = (params) => {
  const {
    i18n: { language },
  } = useTranslation();

  return useQuery(["blogs", params, language], () => fetchBlogs(params));
};

export const useBlogDetailsQuery = (slug) => {
  const {
    i18n: { language },
  } = useTranslation();

  return useQuery(["blog-details", slug, language], () =>
    fetchProductDetails(slug)
  );
};
