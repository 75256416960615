import React from "react";
import HeroBanner from "./HeroBanner";
import LiveAuctionHome from "./LiveAuctionHome";
import RecentNewsHome from "./RecentNewsHome1";

import UpComingHome from "./UpComingHome";

function HomePageOne() {
  return (
    <>
      <HeroBanner />
      <LiveAuctionHome />
      <UpComingHome />

      <RecentNewsHome />
    </>
  );
}

export default HomePageOne;
