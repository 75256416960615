import { useMutation, useQuery } from "react-query";
import {
  fetchUserData,
  userLogin,
  userSignUp,
  userForgetPassword,
  userResetPassword,
  userUpdateProfile,
  userUpdatePassword,
} from "../apis";
import { handleRequestErrorMsg } from "../utils";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const useForgetPasswordMutation = () => {
  const history = useHistory();
  return useMutation(
    async ({ email }) => {
      const res = await userForgetPassword({ email });
      return res;
    },
    {
      onSuccess: () => {
        // handleRequestSuccessMsg(data);
        history.push("/");
      },
      onError: (error) => {
        handleRequestErrorMsg(error);
      },
    }
  );
};

export const useResetPasswordMutation = () => {
  const history = useHistory();
  return useMutation(
    async (data) => {
      const res = await userResetPassword(data);
      return res;
    },
    {
      onSuccess: () => {
        // handleRequestSuccessMsg(data);
        history.push("/login-register");
      },
      onError: (error) => {
        handleRequestErrorMsg(error);
      },
    }
  );
};

export const useUpdateProfileMutation = (onSuccess) => {
  const { t } = useTranslation();
  return useMutation(
    async (data) => {
      const res = await userUpdateProfile(data);
      return res;
    },
    {
      onSuccess: () => {
        toast.success(t("profile_update_success"));
        onSuccess?.();
      },
      onError: (error) => {
        handleRequestErrorMsg(error);
      },
    }
  );
};

export const useUpdatePasswordMutation = (onSuccess) => {
  const { t } = useTranslation();
  return useMutation(
    async (data) => {
      const res = await userUpdatePassword(data);
      return res;
    },
    {
      onSuccess: () => {
        toast.success(t("password_update_success"));
        onSuccess?.();
      },
      onError: (error) => {
        handleRequestErrorMsg(error);
      },
    }
  );
};

export const useLoginMutation = () =>
  useMutation(
    async ({ username, password }) => {
      const res = await userLogin({ username, password: btoa(password) });
      return res;
    },
    {
      onSuccess: (data) => {
        localStorage.setItem("AccessToken", data.AccessToken);
        localStorage.setItem("RefreshToken", data.RefreshToken);
        window.location.href = "/";
      },
      onError: (error) => handleRequestErrorMsg(error),
    }
  );
export const useSignUpMutation = (handleSuccess) =>
  useMutation(
    async (data) => {
      const res = await userSignUp(data);
      return res;
    },
    {
      onSuccess: () => handleSuccess(),
      onError: (error) => handleRequestErrorMsg(error),
    }
  );

export const useGetProfileQuery = () =>
  useQuery("get_profile", fetchUserData, {
    onError: (err) => handleRequestErrorMsg(err),
  });
