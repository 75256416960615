import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { signUpSchema } from "../../../services/schemas";
import { useSignUpMutation } from "../../../services/user";
import { handlePictureUpload } from "../../../utils";
import ImageUploader from "../../common/ImageUploader";

function SignUpWrap({ onRegister }) {
  const [openEye, setOpenEye] = useState(false);
  const { mutate: submitSignUp, isLoading } = useSignUpMutation(onRegister);
  const [isUploading, setIsUploading] = useState(false);
  const [profilePictureURL, setProfilePictureURL] = useState({});
  const { t } = useTranslation();
  const handleEyeIcon = () => {
    setOpenEye(!openEye);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signUpSchema),
  });

  const onSubmit = (data) => {
    submitSignUp({ ...data, ...profilePictureURL });
  };

  const handlePictureUploader = async (file) => {
    setIsUploading(true);
    const imgData = await handlePictureUpload(file);
    setProfilePictureURL(imgData);
    setIsUploading(false);
  };

  return (
    <>
      <div className="signup-section pt-120 pb-120">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="section-bg-top"
        />
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="section-bg-bottom"
        />
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div
                className="form-wrapper wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <div className="form-title">
                  <h3>{t("signup")}</h3>
                  <p>
                    {t("have_an_account")}{" "}
                    <Link
                      to={`${process.env.PUBLIC_URL}/login`}
                      onClick={() =>
                        window.scrollTo({ top: 0, behavior: "smooth" })
                      }
                    >
                      {t("login")} {t("here")}
                    </Link>
                  </p>
                </div>
                <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-inner d-flex">
                        <ImageUploader handleChange={handlePictureUploader} />
                        {isUploading && (
                          <div className="loader-indicator">Uploading...</div>
                        )}
                        {profilePictureURL.ProfilePicture && !isUploading && (
                          <img
                            style={{ maxHeight: "150px" }}
                            alt="images"
                            src={`${process.env.REACT_APP_API_URL}${profilePictureURL.ProfilePicture}`}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-inner">
                        <label>{t("user_name")} *</label>
                        <input
                          type="text"
                          placeholder={t("user_name")}
                          name="username"
                          {...register("username")}
                        />
                        {errors.username && (
                          <span className="error-message">
                            {t(errors.username.message)}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-inner">
                        <label>{t("contact_number")} *</label>
                        <input
                          type="text"
                          placeholder={t("contact_number")}
                          name="phone"
                          {...register("phone")}
                        />
                        {errors.phone && (
                          <span className="error-message">
                            {t(errors.phone.message)}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-inner">
                        <label>{t("first_name")} *</label>
                        <input
                          type="text"
                          placeholder={t("first_name")}
                          name="firstName"
                          {...register("firstName")}
                        />
                        {errors.firstName && (
                          <span className="error-message">
                            {t(errors.firstName.message)}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-inner">
                        <label>{t("last_name")} *</label>
                        <input
                          type="text"
                          placeholder={t("last_name")}
                          name="lastName"
                          {...register("lastName")}
                        />
                        {errors.lastName && (
                          <span className="error-message">
                            {t(errors.lastName.message)}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-inner">
                        <label>{t("enter_email")} *</label>
                        <input
                          type="email"
                          placeholder={t("enter_email")}
                          name="email"
                          {...register("email")}
                        />
                        {errors.email && (
                          <span className="error-message">
                            {t(errors.email.message)}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-inner">
                        <label>{t("password")} *</label>
                        <input
                          type={openEye ? "text" : "password"}
                          name="password"
                          id="password"
                          placeholder="Create A Password"
                          {...register("password")}
                        />
                        <i
                          className={
                            openEye
                              ? "bi bi-eye-slash"
                              : "bi bi-eye-slash bi-eye"
                          }
                          onClick={handleEyeIcon}
                          id="togglePassword"
                        />
                        {errors.password && (
                          <span className="error-message">
                            {t(errors.password.message)}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md-12">
                      <div className="form-agreement form-inner d-flex justify-content-between flex-wrap">
                        <div className="form-group">
                          <input
                            {...register("terms")}
                            type="checkbox"
                            id="html"
                          />
                          <label htmlFor="html">
                            {t("agree_to")}{" "}
                            <Link to={"#"}>{t("terms_policy")}</Link>
                          </label>
                          {errors.terms && (
                            <p className="error-message">
                              {t(errors.terms.message)}
                            </p>
                          )}
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <button
                    disabled={isLoading || isUploading}
                    className="account-btn"
                    type="submit"
                  >
                    {isLoading ? (
                      <span>{t("Loading")}...</span>
                    ) : (
                      t("create_account")
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUpWrap;
