import React from "react";
import { useTranslation } from "react-i18next";

// import { Link } from "react-router-dom";
// import Select from 'react-select';
// import { curencyOptions } from "../../data/data";
import EngIcon from "../../assets/images/icons/flag-eng.png";
import ArIcon from "../../assets/images/icons/egypt.png";

import { AR, EN } from "../../constants";

function TopbarHeader() {
  // const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const {
    i18n: { changeLanguage, language },
    t,
  } = useTranslation();
  const handleLanguageChange = (value) => {
    changeLanguage(value);
    document.body.dir = value === AR ? "rtl" : "ltr";
  };
  return (
    <>
      <div className="topbar">
        <div className="topbar-left d-flex flex-row align-items-center">
          <h6>{t("follow_us")}</h6>
          <ul className="topbar-social-list gap-2">
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/profile.php?id=100085532538405"
              >
                <i className="bx bxl-facebook" />
              </a>
            </li>
            {/* <li>
              <a href="https://www.twitter.com/">
                <i className="bx bxl-twitter" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/">
                <i className="bx bxl-instagram" />
              </a>
            </li>
            <li>
              <a href="https://www.pinterest.com/">
                <i className="bx bxl-pinterest-alt" />
              </a>
            </li> */}
          </ul>
        </div>
        {/* <div className="email-area">
          <h6>
            Email: <a href="mailto:contact@example.com">contact@example.com</a>
          </h6>
        </div> */}
        <div className="topbar-right">
          <ul className="topbar-right-list">
            <li>
              <img src={language === EN ? EngIcon : ArIcon} alt={language} />
              <span>{t("lang")}</span>
              <ul className="topbar-sublist">
                <li onClick={() => handleLanguageChange(EN)}>
                  <img src={EngIcon} alt="English" />
                  <span>English</span>
                </li>
                <li onClick={() => handleLanguageChange(AR)}>
                  <img src={ArIcon} alt="عربي" />
                  <span>عربي</span>
                </li>
              </ul>
            </li>
            {/* <li>Currency
                   <ul className="topbar-sublist">
                        <li><Link to="#"><i className="bi bi-currency-dollar"></i>Usd</Link></li>
                        <li><Link to="#"><i className="bi bi-currency-euro"></i>Euro</Link></li>
                        <li><Link to="#"><i className="bi bi-currency-pound"></i>Pound</Link></li>
                    </ul>
                </li> */}
          </ul>
        </div>
      </div>
    </>
  );
}
export default TopbarHeader;
