import React from "react";
import TimeCounter from "../../common/TimeCounter";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
const AuctionCard = ({ Thumbnail, StartDate, SeName, Name, Price }) => {
  const { t } = useTranslation();
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <div
      className="eg-card c-feature-card1 wow animate fadeInDown"
      data-wow-duration="1.5s"
      data-wow-delay="0.4s"
    >
      <div className="auction-img">
        <img alt="images" src={process.env.REACT_APP_API_URL + Thumbnail} />
        <div className="auction-timer2 gap-lg-3 gap-md-2 gap-1" id="timer8">
          <TimeCounter startTime={StartDate} />
        </div>
      </div>

      <div className="c-feature-content">
        <div className="c-feature-category">{t("darwish")}</div>
        <Link to={`/auction-details/${SeName}`} onClick={scrollTop}>
          <h4>{Name}</h4>
        </Link>
        <p>
          {t("bidding_price")} : <span>{Price}</span>
        </p>
        <div className="auction-card-bttm">
          <Link
            to={`/auction-details/${SeName}`}
            onClick={scrollTop}
            className="eg-btn btn--primary btn--sm"
          >
            {t("view_details")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AuctionCard;
