import React from "react";
import { useTranslation } from "react-i18next";

function WhoWeAreArea() {
  const { t } = useTranslation();

  return (
    <>
      <div className="about-section pt-120 pb-120 overflow-hidden">
        <img
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-top"
          alt="section-bg"
        />
        <div className="container">
          <div className="row d-flex justify-content-center g-4">
            <div className="col-lg-6 col-md-10">
              <div className="about-img-area">
                <img
                  src={process.env.PUBLIC_URL + "/images/about-bg.jpg"}
                  className="img-fluid about-img wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay=".2s"
                  alt="about-img"
                />
                <img
                  src={process.env.PUBLIC_URL + "/images/bg/about-linear.png"}
                  className="img-fluid about-linear"
                  alt=""
                />
                <img
                  src={process.env.PUBLIC_URL + "/images/bg/about-vector.png"}
                  className="img-fluid about-vector"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-10">
              <div
                className="about-content wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <span>{t("about_page.who_we_are")}</span>
                <h2>{t("about_page.headline")}</h2>
                <p className="para">{t("hame_banner_summarize1")}</p>
                <p className="para">{t("hame_banner_summarize2")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhoWeAreArea;
