import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import AuctionDetailsWrap from "./AuctionDetailsWrap";
import { useParams } from "react-router-dom";
import { useGetAuctionDetailsQuery } from "../../../services/auctions";
import Loader from "../../../components/common/Loader";
import { useTranslation } from "react-i18next";
function AuctionDetails() {
  const { slug } = useParams();
  const { data, isLoading } = useGetAuctionDetailsQuery(slug);
  const { t } = useTranslation();

  if (isLoading || !data) return <Loader />;

  const { data: auction } = data || {};
  const { StartTime, EndTime } = auction;
  const desiredDate = new Date();

  const startDate = new Date(StartTime);
  const endDate = new Date(EndTime);
  const isActiveAuction = desiredDate >= startDate && desiredDate < endDate;
  const isUpcomingAuction = desiredDate < startDate;

  return (
    <>
      <Breadcrumb
        pageName={t("auction_details")}
        pageTitle={t("auction_details")}
      />
      <AuctionDetailsWrap
        auction={auction}
        isActiveAuction={isActiveAuction}
        isUpcomingAuction={isUpcomingAuction}
      />
    </>
  );
}

export default AuctionDetails;
