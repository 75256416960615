import React from "react";
import { useTranslation } from "react-i18next";
import { useGetAuctionsQuery } from "../../../services/auctions";
import { AuctionTypes } from "../../../constants";
import AuctionCard from "../Auctions/AuctionCard";
function LiveAuctionHome() {
  const { data, isLoading } = useGetAuctionsQuery({
    type: AuctionTypes.LIVE,
  });
  const { t } = useTranslation();

  if (isLoading) return <div>Loading...</div>;
  const { data: liveAuctions } = data || [];

  return (
    <>
      <div className="live-auction pb-120 mt-4">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg"
        />
        <div className="container position-relative">
          <img
            alt="images"
            src={process.env.PUBLIC_URL + "/images/bg/dotted1.png"}
            className="dotted1"
          />
          <img
            alt="images"
            src={process.env.PUBLIC_URL + "/images/bg/dotted1.png"}
            className="dotted2"
          />
          <div className="row d-flex justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
              <div className="section-title1">
                <h2>{t("live_auction")}</h2>
                <p className="mb-0">{t("live_auction_summarize")}</p>
              </div>
            </div>
          </div>
          <div className="row gy-4 mb-60 d-flex justify-content-center">
            {liveAuctions?.map(
              ({ Id, EndDate, Name, Price, Thumbnail, SeName }) => (
                <div key={Id} className="col-lg-4 col-md-6 col-sm-10 ">
                  <AuctionCard
                    Name={Name}
                    Price={Price}
                    StartDate={EndDate}
                    Thumbnail={Thumbnail}
                    Id={Id}
                    SeName={SeName}
                  />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default LiveAuctionHome;
