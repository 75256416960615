import React, { useState } from "react";
import Breadcrumb from "../../common/Breadcrumb";
import DashboardMenu from "./DashboardMenu";
import DashboardWrap from "./DashboardWrap";
import ContentOfDashboardTab from "./ContentOfDashboardTab/ContentOfDashboardTab";
import ContentOfOrder from "./ContentOfOrder/ContentOfOrder";
import ContentOfProfile from "./ContentOfProfile/ContentOfProfile";
import ContentOfPurchase from "./ContentOfPurchase/ContentOfPurchase";

function Dashboard() {
  const [activeTab, setActiveTab] = useState("profile");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  let content;
  switch (activeTab) {
    case "dashboard":
      content = <ContentOfDashboardTab />;
      break;
    case "profile":
      content = <ContentOfProfile />;
      break;
    case "orders":
      content = <ContentOfOrder />;
      break;
    case "purchases":
      content = <ContentOfPurchase />;
      break;
    default:
      content = <ContentOfDashboardTab />;
  }

  return (
    <>
      <Breadcrumb pageName="Dashboard" pageTitle="Dashboard" />
      <DashboardWrap>
        <DashboardMenu activeTab={activeTab} handleTabClick={handleTabClick} />
        <div className="col-lg-9">
          <div className="tab-content" id="v-pills-tabContent">
            {content}
          </div>
        </div>
      </DashboardWrap>
    </>
  );
}

export default Dashboard;
