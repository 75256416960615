import axios from "./axios";

export const userLogin = async (data) => {
  const res = await axios.post("/tokenweb/login", data);
  return res.data;
};
export const userSignUp = async (data) => {
  const res = await axios.post("/customer/register", data);
  return res.data;
};
export const fetchUserData = async () => {
  const res = await axios.get("/account/me");
  return res.data;
};

export const fetchBlogs = async (params) =>
  axios({
    url: `/blog`,
    method: "GET",
    params,
  });
export const fetchProductDetails = async (slug) =>
  axios({
    url: `/${slug}`,
    method: "GET",
  });

export const userUpdateProfile = (data) =>
  axios({
    url: `/account/update`,
    method: "POST",
    data,
  });
export const userUpdatePassword = (data) =>
  axios({
    url: `/account/password`,
    method: "POST",
    data,
  });
userUpdatePassword;

export const userResetPassword = (data) =>
  axios({
    url: `/Customer/ForgetPasswordConfirm`,
    method: "POST",
    data,
  });

export const userForgetPassword = (data) =>
  axios({
    url: `/Customer/ForgetPassword`,
    method: "POST",
    data,
  });

export const getAuctions = (params) =>
  axios({
    url: `/product/AuctionProducts`,
    method: "GET",
    params,
  });

export const getHighestBid = (id) =>
  axios({
    url: `/product/${id}/highestBid`,
    method: "GET",
  });
export const getBidsHistory = (id) =>
  axios({
    url: `/product/${id}/bidsHistory`,
    method: "GET",
  });
export const addBid = (data, id) =>
  axios({
    url: `/product/${id}/bids`,
    method: "POST",
    data,
  });
