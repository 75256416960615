import { useMutation, useQuery } from "react-query";
import { handleRequestErrorMsg } from "../utils";
import {
  addBid,
  fetchProductDetails,
  getAuctions,
  getBidsHistory,
  getHighestBid,
} from "../apis";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const useGetAuctionsQuery = (params) => {
  const {
    i18n: { language },
  } = useTranslation();
  return useQuery(
    ["get_auctions", language, params],
    async () => await getAuctions(params),
    {
      onError: (err) => handleRequestErrorMsg(err),
    }
  );
};

export const useGetAuctionDetailsQuery = (slug) => {
  const {
    i18n: { language },
  } = useTranslation();
  return useQuery(
    ["get_auction_details", language, slug],
    async () => await fetchProductDetails(slug),
    {
      onError: (err) => handleRequestErrorMsg(err),
    }
  );
};

export const useAddBidMutation = () =>
  useMutation(
    async ({ bid, id }) => {
      const res = await addBid({ bid }, id);
      return res;
    },
    {
      onError: (error) => handleRequestErrorMsg(error),
    }
  );

export const useGetHighestBidQuery = (id, currentHighest, isActiveAuction) => {
  const { t } = useTranslation();

  return useQuery(
    ["get_highest_bid", id],
    async () => await getHighestBid(id),
    {
      enabled: isActiveAuction,
      onSuccess: (res) => {
        if (res.data.bid?.Amount && res.data.bid?.Amount !== currentHighest) {
          toast.info(t("new_bid_placed"));
        }
      },
      onError: (err) => handleRequestErrorMsg(err),
      refetchInterval: 5000,
    }
  );
};

export const useGetBidHistoryQuery = (id, oldData) => {
  const { t } = useTranslation();
  return useQuery(
    ["get_bids_history", id],
    async () => await getBidsHistory(id),
    {
      onSuccess: (res) => {
        if (oldData && res.data.length === oldData.length)
          toast.info(t("no_new_data"));
      },
      onError: (err) => handleRequestErrorMsg(err),
    }
  );
};
