import { toast } from "react-toastify";
import axiosInstance from "../apis/axios";

export const isAuth = () => !!localStorage["AccessToken"];

export const invalidUserToken = () => {
  localStorage.removeItem("AccessToken");
  localStorage.removeItem("RefreshToken");
  window.location.href = "/login";
};

export const userLogout = () => {
  localStorage.removeItem("AccessToken");
  localStorage.removeItem("RefreshToken");
  window.location.href = "/";
};

export const handleRequestSuccessMsg = (data) => {
  try {
    const { message } = data;
    return toast.success(message);
  } catch (err) {
    // TODO translate
    return;
  }
};

export const handleRequestErrorMsg = (error) => {
  try {
    let data = error.data;
    if (!data) data = error.response.data;

    const keys = Object.keys(data);
    return toast.error(data[keys[0]][0]);
  } catch (err) {
    // TODO translate
    return toast.error("Something went wrong");
  }
};

export const formatDateMDY = (dateString) => {
  const date = new Date(dateString);
  const options = { year: "numeric", month: "short", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

export const formattedTime = (date) => {
  const dateTime = new Date(date);
  return dateTime.toLocaleTimeString();
};

export const handlePictureUpload = async (file) => {
  const formData = new FormData();
  formData.append("profilePicture", file);

  const response = await axiosInstance.post(
    "/account/profilepicture",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-Response-View": "Json",
      },
    }
  );
  return response?.data?.data;
};
