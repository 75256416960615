import React from "react";
import { Link } from "react-router-dom";
// import BlogAuthorAndTag from "./BlogAuthorAndTag";
// import BlogComments from "./BlogComments";
import BlogContent from "./BlogContent";
import { useTranslation } from "react-i18next";
import { formatDateMDY } from "../../../utils";
// import BlogPostCategory from "./BlogPostCategory";
// import BlogRecentPostWidget from "./BlogRecentPostWidget";
// import BlogSearchWidget from "./BlogSearchWidget";
// import BlogSidebarBanner from "./BlogSidebarBanner";
// import BlogSocialLink from "./BlogSocialLink";
// import CommentForm from "./CommentForm";

function BlogDetailsWrap({ blog }) {
  const { Body, BodyOverview, CreatedOn, PictureModel, Title } = blog;
  const { t } = useTranslation();
  return (
    <>
      <div className="blog-details-section pb-120">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-top"
        />
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-bottom"
        />
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="blog-details-single">
              <div className="blog-img">
                <img
                  alt="images"
                  src={process.env.REACT_APP_API_URL + PictureModel.ImageUrl}
                  className="img-fluid wow fadeInDown"
                  data-wow-duration="1.5s"
                  data-wow-delay=".2s"
                />
              </div>
              <ul className="blog-meta gap-2">
                <li>
                  <Link to={"#"}>
                    <img
                      alt="images"
                      src={
                        process.env.PUBLIC_URL + "/images/icons/calendar.svg"
                      }
                    />
                    {formatDateMDY(CreatedOn)}
                  </Link>
                </li>
                <li>
                  <Link to={"#"}>
                    <img
                      alt="images"
                      src={process.env.PUBLIC_URL + "/images/icons/tags.svg"}
                    />
                    {t("auction")}
                  </Link>
                </li>
                <li>
                  <Link to={"#"}>
                    <img
                      alt="images"
                      src={process.env.PUBLIC_URL + "/images/icons/admin.svg"}
                    />
                    {t("darwish")}
                  </Link>
                </li>
              </ul>
              <h3 className="blog-title">{Title}</h3>
              <BlogContent Body={Body} BodyOverview={BodyOverview} />
              {/* <BlogAuthorAndTag /> */}
              {/* <BlogComments />
              <CommentForm /> */}
            </div>
          </div>
          {/* <div className="col-lg-4">
            <div className="blog-sidebar">
              <BlogSearchWidget />
              <BlogRecentPostWidget />
              <BlogPostCategory />
              <BlogSocialLink />
              <BlogSidebarBanner />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default BlogDetailsWrap;
