import React from "react";
// import TimeCounter from "../../common/TimeCounter";
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { useTranslation } from "react-i18next";

import { useGetAuctionsQuery } from "../../../services/auctions";
import { AuctionTypes } from "../../../constants";
import AuctionCard from "../Auctions/AuctionCard";

SwiperCore.use([Navigation, Autoplay]);

const upcomingSlider = {
  slidesPerView: 1,
  speed: 1000,
  spaceBetween: 24,
  loop: true,
  roundLengths: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: "true",
  },
  navigation: {
    nextEl: ".coming-prev1",
    prevEl: ".coming-next1",
  },

  breakpoints: {
    280: {
      slidesPerView: 1,
    },
    480: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 2,
    },
    1200: {
      slidesPerView: 3,
    },
  },
};

function UpComingHome1() {
  const { t } = useTranslation();
  const { data, isLoading } = useGetAuctionsQuery({
    type: AuctionTypes.UPCOMING,
  });

  if (isLoading) return <div>Loading...</div>;
  const { data: upcomingAuctions } = data || [];
  return !upcomingAuctions.length ? null : (
    <>
      <div className="upcoming-seciton pb-120">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg"
        />
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
              <div className="section-title1">
                <h2>{t("upcoming_auctions")}</h2>
                <p className="mb-0">{t("upcoming_auctions_summarize")}</p>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <Swiper {...upcomingSlider} className="swiper upcoming-slider">
              <div className="swiper-wrapper">
                {upcomingAuctions?.map(
                  ({ Name, Price, StartDate, Thumbnail, Id, SeName }) => (
                    <SwiperSlide key={Id} className="swiper-slide">
                      <AuctionCard
                        Name={Name}
                        Price={Price}
                        StartDate={StartDate}
                        Thumbnail={Thumbnail}
                        Id={Id}
                        SeName={SeName}
                      />
                    </SwiperSlide>
                  )
                )}
              </div>
            </Swiper>
            <div className="slider-bottom d-flex justify-content-between align-items-center">
              <div className="swiper-pagination style-3 d-lg-block d-none" />
              <div className="slider-arrows coming-arrow d-flex gap-3">
                <div
                  className="coming-prev1 swiper-prev-arrow"
                  tabIndex={0}
                  role="button"
                  aria-label="Previous slide"
                >
                  <i className="bi bi-arrow-left" />
                </div>
                <div
                  className="coming-next1 swiper-next-arrow"
                  tabIndex={0}
                  role="button"
                  aria-label="Next slide"
                >
                  <i className="bi bi-arrow-right" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpComingHome1;
