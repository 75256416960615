import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "./components/Layout";
import MainLayout from "./components/layout/MainLayout";
import Contact from "./components/page/contact/Contact";
import ErrorPage from "./components/page/error/ErrorPage";
import SignUp from "./components/page/signUp/SignUp";
import Faq from "./components/page/faq/Faq";
import Login from "./components/page/login/Login";
import AuctionDetails from "./components/page/auctionDetails/AuctionDetails";
import Dashboard from "./components/page/dashboard/Dashboard";
import Blog from "./components/page/blog/Blog";
import BlogDetails from "./components/page/BlogDetails/BlogDetails";
import Auctions from "./components/page/Auctions/Auctions";
import HowItWork from "./components/page/howItWork/HowItWork";
import About from "./components/page/about/About";
import Merchant from "./components/page/joinMerchant/Merchant";
import "react-loading-skeleton/dist/skeleton.css";
import "./index.css";
import ProtectedRoute from "./components/page/ProtectedRoute";
import { useTranslation } from "react-i18next";
import { EN } from "./constants";

function App() {
  const {
    i18n: { language },
  } = useTranslation();
  useEffect(() => {
    document.body.dir = language === EN ? "ltr" : "rtl";
  });
  return (
    <BrowserRouter basename="/">
      <Switch>
        <Route exact path="/" component={MainLayout} />
        <Layout>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/about`}
            component={About}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contact`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/error`}
            component={ErrorPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/signup`}
            component={SignUp}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/login`}
            component={Login}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/auction-details/:slug`}
            component={AuctionDetails}
          />
          <ProtectedRoute
            exact
            path={`${process.env.PUBLIC_URL}/dashboard`}
            component={Dashboard}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog`}
            component={Blog}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-details/:slug`}
            component={BlogDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/auctions/:type`}
            component={Auctions}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/how-works`}
            component={HowItWork}
          />
          <Route exact path={`${process.env.PUBLIC_URL}/faq`} component={Faq} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/join-merchant`}
            component={Merchant}
          />
        </Layout>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
