import React from "react";
import Skeleton from "react-loading-skeleton";

function CardSkeleton() {
  return (
    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-10 rounded">
      <div className="card p-2">
        <Skeleton height={275} />
        <div className="card-body">
          <h5 className="card-title p-3">
            <Skeleton />
          </h5>
          <div className="card-text">
            <Skeleton count={2} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardSkeleton;
