import React, { useState } from "react";
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
} from "../../../../services/user";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import ImageUploader from "../../../common/ImageUploader";
import { handlePictureUpload } from "../../../../utils";
import PasswordSection from "./PasswordSection";

const schema = yup.object().shape({
  FirstName: yup.string().required("errors.firstName.required"),
  LastName: yup.string().required("errors.lastName.required"),
  Email: yup
    .string()
    .email("errors.email.invalid")
    .required("errors.email.required"),
  Phone: yup.string().required("errors.phone.required"),
});

function ContentOfProfile() {
  const [isUploading, setIsUploading] = useState(false);
  const [profilePictureURL, setProfilePictureURL] = useState({});

  const { data: profile, isLoading } = useGetProfileQuery();
  const { mutate: updateProfile } = useUpdateProfileMutation();
  const { t } = useTranslation();
  const {
    Email: initialEmail,
    FirstName: initialFirstName,
    LastName: initialLastName,
    Phone: initialPhone,
    ProfilePicture,
    ProfilePictureId,
  } = profile || {};

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handlePictureUploader = async (file) => {
    setIsUploading(true);
    const imgData = await handlePictureUpload(file);
    setProfilePictureURL(imgData);
    setIsUploading(false);
  };

  const onSubmit = (data) => {
    const profileImg = profilePictureURL.ProfilePicture
      ? profilePictureURL
      : { ProfilePicture, ProfilePictureId };
    updateProfile({ ...data, ...profileImg });
  };

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <>
      <div
        id="v-pills-profile"
        role="tabpanel"
        aria-labelledby="v-pills-profile-tab"
      >
        <div className="dashboard-profile">
          <div className="owner">
            <div className="image">
              <img
                alt="images"
                src={
                  ProfilePicture
                    ? process.env.REACT_APP_API_URL + ProfilePicture
                    : "/images/logo-64.png"
                }
              />
            </div>
            <div className="content">
              <h3>
                {initialFirstName} {initialLastName}
              </h3>
            </div>
          </div>
          <div className="form-wrapper">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-inner d-flex">
                    <ImageUploader handleChange={handlePictureUploader} />
                    {isUploading && (
                      <div className="loader-indicator">Uploading...</div>
                    )}
                    {profilePictureURL.ProfilePicture && !isUploading && (
                      <img
                        style={{ maxHeight: "150px", maxWidth: "150px" }}
                        alt="images"
                        src={`${process.env.REACT_APP_API_URL}${profilePictureURL.ProfilePicture}`}
                      />
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <div className="form-inner">
                    <label>{t("first_name")} *</label>
                    <input
                      type="text"
                      defaultValue={initialFirstName}
                      placeholder="Your first name"
                      {...register("FirstName")}
                    />
                    {errors.FirstName && (
                      <p className="error-message">
                        {t(errors.FirstName.message)}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <div className="form-inner">
                    <label>{t("last_name")} *</label>
                    <input
                      type="text"
                      defaultValue={initialLastName}
                      placeholder="Your last name"
                      {...register("LastName")}
                    />
                    {errors.LastName && (
                      <p className="error-message">
                        {t(errors.LastName.message)}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-inner">
                    <label>{t("contact_number")} *</label>
                    <input
                      name="Phone"
                      defaultValue={initialPhone}
                      {...register("Phone")}
                    />
                    {errors.Phone && (
                      <p className="error-message">{t(errors.Phone.message)}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-inner">
                    <label>{t("email")} *</label>
                    <input
                      defaultValue={initialEmail}
                      name="email"
                      type="email"
                      {...register("Email")}
                    />
                    {errors.Email && (
                      <p className="error-message">{t(errors.Email.message)}</p>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <div className="button-group mt-2">
                    <button type="submit" className="eg-btn profile-btn">
                      {t("update_profile")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <PasswordSection />
          </div>
        </div>
      </div>
    </>
  );
}

export default ContentOfProfile;
