import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Pagination({ currentPage, totalPages, onPageChange }) {
  const { t } = useTranslation();
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <div className="row">
        <nav className="pagination-wrap">
          <ul className="pagination d-flex justify-content-center gap-md-3 gap-2">
            <li className={`page-item${currentPage === 1 ? " disabled" : ""}`}>
              <Link
                className="page-link"
                to={"#"}
                onClick={() => onPageChange(currentPage - 1)}
              >
                {t("prev")}
              </Link>
            </li>
            {pageNumbers.map((pageNumber) => (
              <li
                className={`page-item${
                  pageNumber === currentPage ? " active" : ""
                }`}
                key={pageNumber}
              >
                <Link
                  className="page-link"
                  to={"#"}
                  onClick={() => onPageChange(pageNumber)}
                >
                  {pageNumber}
                </Link>
              </li>
            ))}
            <li
              className={`page-item${
                currentPage === totalPages ? " disabled" : ""
              }`}
            >
              <Link
                className="page-link"
                to={"#"}
                onClick={() => onPageChange(currentPage + 1)}
              >
                {t("next")}
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Pagination;
