import React from "react";
import { Link } from "react-router-dom";
import { formatDateMDY } from "../../../utils";

function BlogSingleItem(props) {
  return (
    <>
      <div
        className="single-blog-style1 wow fadeInDown rounded"
        data-wow-duration="1.5s"
        data-wow-delay=".4s"
      >
        <div className="blog-img" style={{ maxHeight: "275px" }}>
          <Link to={"#"} className="blog-date">
            <i className="bi bi-calendar-check" />
            {formatDateMDY(props.date)}
          </Link>
          <img
            alt="images"
            src={`${process.env.REACT_APP_API_URL}${props.image}`}
          />
        </div>
        <div className="blog-content">
          <h5>
            <Link to={`${process.env.PUBLIC_URL}/blog-details`}>
              {props.blogContent}
            </Link>
          </h5>
          <div className="blog-meta">
            <div className="author">
              <img
                alt="images"
                src={process.env.PUBLIC_URL + "/images/blog/author1.png"}
              />
              <Link to={"#"} className="author-name">
                {props.authorName}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogSingleItem;
