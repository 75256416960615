import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

function TimeCounter({ startTime }) {
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");
  const { t } = useTranslation();
  let interval = useRef();

  const startTimer = (startTime) => {
    const countdownDate = new Date(startTime);
    interval.current = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setTimerDays(days.toString().padStart(2, "0"));
        setTimerHours(hours.toString().padStart(2, "0"));
        setTimerMinutes(minutes.toString().padStart(2, "0"));
        setTimerSeconds(seconds.toString().padStart(2, "0"));
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer(startTime);

    return () => {
      clearInterval(interval.current);
    };
  }, [startTime]);

  return (
    <>
      <div className="countdown-single">
        <h5 id="days9">{timerDays}</h5>
        <span>{t("days")}</span>
      </div>
      <div className="countdown-single">
        <h5 id="hours9">{timerHours}</h5>
        <span>{t("hours")}</span>
      </div>
      <div className="countdown-single">
        <h5 id="minutes9">{timerMinutes}</h5>
        <span>{t("mins")}</span>
      </div>
      <div className="countdown-single">
        <h5 id="seconds9">{timerSeconds}</h5>
        <span>{t("secs")}</span>
      </div>
    </>
  );
}

export default TimeCounter;
