import React from "react";
function BlogContent({ Body, BodyOverview }) {
  return (
    <>
      <div className="blog-content">
        <div dangerouslySetInnerHTML={{ __html: BodyOverview }}></div>
        <div dangerouslySetInnerHTML={{ __html: Body }}></div>
      </div>
    </>
  );
}

export default BlogContent;
