import React from "react";

import Breadcrumb from "../../common/Breadcrumb";
// import WhyChooseUs from "../howItWork/WhyChooseUs";
// import AboutTestimonial from "./AboutTestimonial";
import WhoWeAreArea from "./WhoWeAreArea";
import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb pageName={t("about")} pageTitle={t("about")} />
      <WhoWeAreArea />
      {/* <WhyChooseUs />
      <AboutTestimonial /> */}
    </>
  );
}

export default About;
