import React from "react";
// import DashboardIcon from "./icons/DashboardIcon";
import UserIcon from "./icons/UserIcon";
// import OrderBiddingIcon from "./icons/OrderBiddingIcon";
import LogoutIcon from "./icons/LogoutIcon";
import { userLogout } from "../../../utils";
import { useTranslation } from "react-i18next";

function DashboardMenu({ activeTab, handleTabClick }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="col-lg-3">
        <div
          className="nav flex-column nav-pills gap-4 wow fadeInUp"
          data-wow-duration="1.5s"
          data-wow-delay=".2s"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          {/* <button
            className={`nav-link nav-btn-style mx-auto mb-20 ${
              activeTab === "dashboard" ? "active" : ""
            }`}
            id="v-pills-dashboard-tab"
            onClick={() => handleTabClick("dashboard")}
            type="button"
            role="tab"
            aria-controls="v-pills-dashboard"
            aria-selected={activeTab === "dashboard" ? "true" : "false"}
          >
            <DashboardIcon />
            Dashboard
          </button> */}
          <button
            className={`nav-link nav-btn-style mx-auto mb-20 ${
              activeTab === "profile" ? "active" : ""
            }`}
            id="v-pills-profile-tab"
            onClick={() => handleTabClick("profile")}
            type="button"
            role="tab"
            aria-controls="v-pills-profile"
            aria-selected={activeTab === "profile" ? "true" : "false"}
          >
            <i className="lar la-user" />
            <UserIcon />
            {t("my_profile")}
          </button>
          {/* <button
            className={`nav-link nav-btn-style mx-auto mb-20 ${
              activeTab === "orders" ? "active" : ""
            }`}
            id="v-pills-order-tab"
            onClick={() => handleTabClick("orders")}
            type="button"
            role="tab"
            aria-controls="v-pills-order"
            aria-selected={activeTab === "orders" ? "true" : "false"}
          >
            <OrderBiddingIcon />
            Order Bidding
          </button> */}
          <button
            className={`nav-link nav-btn-style mx-auto`}
            onClick={() => userLogout()}
            type="button"
            role="tab"
            aria-selected={activeTab === "purchases" ? "true" : "false"}
          >
            <LogoutIcon />
            {t("logout")}
          </button>
        </div>
      </div>
    </>
  );
}

export default DashboardMenu;
