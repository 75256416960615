import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";
import Counter from "../../common/Counter";

import "react-image-gallery/styles/css/image-gallery.css";
import PlacingBid from "../../common/PlacingBid";

function AuctionDetailsInfo({
  auction,
  isActiveAuction,
  isUpcomingAuction,
  onPlaceBid,
  amount,
  isLoading,
  allowedBidPrice,
  highestAmount,
}) {
  const { Name, ShortDescription, Price: StartPrice, PictureModels, EndTime, StartTime } = auction;

  const [highlight, setHighlight] = useState(false);
  const { t } = useTranslation();

  const infoMsg = !isActiveAuction && isUpcomingAuction ? t("auction_not_started") : t("auction_ended");
  const CountTime = isActiveAuction ? EndTime : StartTime;

  useEffect(() => {
    if (highestAmount !== amount && !isLoading) {
      setHighlight(true); // Apply highlight effect
      setTimeout(() => {
        setHighlight(false); // Remove highlight effect after 4 seconds
      }, 4000);
    }
  }, [highestAmount, isLoading]);

  return (
    <>
      <div className="row g-4 mb-50">
        <div className="col-xl-6 col-lg-7 d-flex flex-row align-items-start justify-content-lg-start justify-content-center flex-md-nowrap flex-wrap gap-4">
          <div
            className="tab-content mb-4 d-flex justify-content-lg-start justify-content-center  wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay=".4s">
            <div className="tab-pane big-image fade show active" id="gallery-img1">
              <div className="auction-gallery-timer d-flex align-items-center justify-content-center flex-wrap">
                <h3 id="countdown-timer-1">
                  <Counter time={CountTime} />
                </h3>
              </div>
              <ImageGallery
                items={PictureModels.map(({ ImageUrl }) => ({
                  original: process.env.REACT_APP_API_URL + ImageUrl,
                  thumbnail: process.env.REACT_APP_API_URL + ImageUrl,
                }))}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-5">
          <div className="product-details-right  wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
            <h3>{Name}</h3>
            <p className="para">{ShortDescription}</p>
            {isActiveAuction && (
              <h4>
                {t("bidding_price")}: <span className={highlight ? "lightning" : ""}>{StartPrice + highestAmount}</span>
              </h4>
            )}
            {isActiveAuction ? (
              <div className="bid-form">
                <div className="form-title">
                  <h5>{t("bid_now")}</h5>
                  <p className={highlight ? "lightning" : ""}>
                    {t("amount")} : {t("egp")}
                    {StartPrice + highestAmount + allowedBidPrice}
                  </p>
                </div>
                {/* <div className="form-inner gap-2">
                  <button className="eg-btn btn--primary btn--sm" onClick={onPlaceBid}>
                    {t("place_bid")}
                  </button>
                </div> */}
                <PlacingBid onPlaceBid={onPlaceBid} />
              </div>
            ) : (
              <div className="bid-form">
                <div className="form-title">
                  <h5>{infoMsg}</h5>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AuctionDetailsInfo;
