import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import WOW from "wowjs";
function Footer() {
  const { t } = useTranslation();
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <footer>
        <div className="footer-top">
          <div className="container">
            <div className="row gy-5">
              <div className="col-lg-3 col-md-6">
                <div className="footer-item">
                  <Link tio={`${process.env.PUBLIC_URL}/`} onClick={scrollTop}>
                    <img
                      alt="images"
                      src={process.env.PUBLIC_URL + "/images/logo-64.png"}
                    />
                  </Link>
                  <p>{t("live_auction_summarize")}</p>
                  <form>
                    <div className="input-with-btn d-flex jusify-content-start align-items-strech">
                      <input type="text" placeholder="Enter your email" />
                      <button type="submit">
                        <img
                          alt="images"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/send-icon.svg"
                          }
                        />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex justify-content-lg-center">
                <div className="footer-item">
                  <h5>{t("navigation")}</h5>
                  <ul className="footer-list">
                    <li>
                      <Link onClick={scrollTop} to={`/auctions/current`}>
                        {t("auctions")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link onClick={scrollTop} to={`/how-works`}>
                        {t("how_it_work")}
                      </Link>
                    </li> */}
                    <li>
                      <Link onClick={scrollTop} to={`/login`}>
                        {t("my_account")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/about`}
                      >
                        {t("about_us")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/blog`}
                      >
                        {t("our_news_feed")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row d-flex align-items-center g-4">
              <div className="col-lg-6 d-flex justify-content-lg-start justify-content-center">
                <p>
                  Copyright 2023 <Link to={"/"}>Darwish Auction</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
