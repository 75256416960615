import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useUpdatePasswordMutation } from "../../../../services/user";

const passwordSchema = yup.object().shape({
  OldPassword: yup
    .string()
    .min(6, "errors.password.min")
    .required("errors.password.required"),
  NewPassword: yup
    .string()
    .min(6, "errors.password.min")
    .required("errors.password.required"),
});

const PasswordSection = () => {
  const [openEye, setOpenEye] = useState();
  const { mutate: updatePassword } = useUpdatePasswordMutation();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(passwordSchema),
  });
  const onSubmit = (data) => {
    updatePassword({
      OldPassword: btoa(data.OldPassword),
      NewPassword: data.NewPassword,
    });
  };

  const handleEyeIcon = () => {
    setOpenEye(!openEye);
  };
  return (
    <div className="form-wrapper">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-inner">
              <label>{t("old_password")} *</label>
              <div style={{ position: "relative" }}>
                <input
                  type={openEye ? "password" : "text"}
                  {...register("OldPassword")}
                  placeholder={t("old_password")}
                />
                <i
                  className={
                    openEye === 1 ? "bi bi-eye-slash" : "bi bi-eye-slash bi-eye"
                  }
                  id="togglePassword"
                  onClick={handleEyeIcon}
                />
              </div>
              {errors.OldPassword && (
                <p className="error-message">{t(errors.OldPassword.message)}</p>
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="form-inner mb-0">
              <label>{t("new_password")} *</label>
              <div style={{ position: "relative" }}>
                <input
                  type={openEye ? "password" : "text"}
                  {...register("NewPassword")}
                  placeholder={t("new_password")}
                />
                <i
                  className={
                    openEye === 1 ? "bi bi-eye-slash" : "bi bi-eye-slash bi-eye"
                  }
                  onClick={handleEyeIcon}
                  id="togglePassword2"
                />
              </div>
              {errors.NewPassword && (
                <p className="error-message">{t(errors.NewPassword.message)}</p>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="button-group mt-2">
              <button type="submit" className="eg-btn profile-btn">
                {t("update_password")}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default PasswordSection;
