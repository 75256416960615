import axios from "axios";

import jwt_decode from "jwt-decode";
import { invalidUserToken } from "../utils";
import { REQUEST_LANG } from "../constants";
import { toast } from "react-toastify";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-Response-View": "Json",
  },
});

// Add a request interceptor to add an authentication token to the headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("AccessToken");
    const language = REQUEST_LANG[localStorage.getItem("i18nextLng")];
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (language) {
      config.headers["Accept-Language"] = language;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    console.log(response?.data?.message, response?.data?.success);
    if (response?.data?.message && !response?.data?.success) {
      toast.error(response.data.message);
    }
    return response;
  },
  (error) => {
    console.log(error);
    const config = error?.config;
    if (error.code === "ERR_NETWORK" && config?.url !== "/tokenweb/login") {
      const token = localStorage.getItem("AccessToken");
      if (config.headers.Authorization && token) {
        const decodedToken = jwt_decode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          const refreshToken = localStorage.getItem("RefreshToken");
          if (refreshToken) {
            return axios
              .post("tokenweb/refresh/", { refresh: refreshToken })
              .then((response) => {
                const newToken = response.data.access;
                localStorage.setItem("AccessToken", newToken);
                config.headers.Authorization = `Bearer ${newToken}`;
                return axios(config);
              })
              .catch((error) => {
                invalidUserToken();
                return Promise.reject(error);
              });
          } else {
            invalidUserToken();
            return Promise.reject("No refresh token available.");
          }
        }
      }
      invalidUserToken();
      return Promise.reject("Access token expired or missing.");
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
