import React from "react";
import AuctionCard from "./AuctionCard";

function AuctionWrap({ auctions }) {
  return (
    <>
      <div className="live-auction-section pt-120 pb-120">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-top"
        />
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-bottom"
        />
        <div className="container">
          <div className="row gy-4 mb-60 d-flex justify-content-center">
            {auctions.map(({ Id, Name, SeName, Price, Thumbnail, EndDate }) => (
              <div key={Id} className="col-lg-4 col-md-6 col-sm-10">
                <AuctionCard
                  Thumbnail={Thumbnail}
                  Price={Price}
                  Name={Name}
                  SeName={SeName}
                  StartDate={EndDate}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default AuctionWrap;
