import React from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../common/Breadcrumb";
import BlogDetailsWrap from "./BlogDetailsWrap";
import { useBlogDetailsQuery } from "../../../services/blogs";
import Loader from "../../common/Loader";
import { useTranslation } from "react-i18next";
function BlogDetails() {
  const params = useParams();
  const { t } = useTranslation();
  const { data, isLoading } = useBlogDetailsQuery(params.slug);
  if (isLoading) return <Loader />;

  const { data: blog } = data || {};
  return (
    <>
      <Breadcrumb pageName={t("blog_details")} pageTitle={t("blog_details")} />
      <BlogDetailsWrap blog={blog} />
    </>
  );
}

export default BlogDetails;
