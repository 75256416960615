import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";
const ImageUploader = ({ handleChange }) => {
  const { t } = useTranslation();
  return (
    <FileUploader
      types={["jpg", "jpeg", "png"]}
      maxSize={2}
      handleChange={handleChange}
    >
      <div className="file-picker">
        <p className="file-picker-text">{t("drag_drop_here")}</p>
        <p className="file-picker-text">{t("or")}</p>
        <button className="file-picker-button btn">{t("select_img")}</button>
      </div>
    </FileUploader>
  );
};

export default ImageUploader;
