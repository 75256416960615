import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PlacingBid({ onPlaceBid }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAgree = () => {
    onPlaceBid();
    setOpen(false);
  };

  const handleDisAgree = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <div className="form-inner gap-2">
        <button className="eg-btn btn--primary btn--sm" onClick={handleClickOpen}>
          {t("place_bid")}
        </button>
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDisAgree}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{t("place_bid_confirmation_title")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{t("place_bid_confirmation_desc")}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button variant="outlined" color="success" onClick={handleAgree}>
            {t("agree")}
          </Button>
          <Button variant="outlined" color="error" onClick={handleDisAgree}>
            {t("disagree")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
