import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import BlogWrap from "./BlogWrap";
import { useTranslation } from "react-i18next";

function Blog() {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb pageTitle={t("blogs")} pageName={t("our_blogs")} />
      <BlogWrap />
    </>
  );
}

export default Blog;
