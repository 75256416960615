import * as yup from "yup";

export const loginSchema = yup.object().shape({
  username: yup.string().required("errors.userName.required"),
  password: yup
    .string()
    .min(6, "errors.password.min")
    .required("errors.password.required"),
});

export const signUpSchema = yup.object().shape({
  firstName: yup.string().required("errors.firstName.required"),
  lastName: yup.string().required("errors.lastName.required"),
  username: yup
    .string()
    .required("errors.userName.required")
    .test("no-spaces", "errors.userName.spacesNotAllowed", (value) => {
      return value && !/\s/.test(value); // Ensure there are no spaces in the username
    }),
  phone: yup.string().required("errors.phone.required"),
  email: yup
    .string()
    .email("errors.email.invalid")
    .required("errors.email.required"),
  password: yup
    .string()
    .min(6, "errors.password.min")
    .required("errors.password.required"),
  terms: yup.boolean().oneOf([true], "errors.terms.required"),
});
