import React, { useEffect, useState } from "react";
import { useGetBidHistoryQuery } from "../../../services/auctions";
import { formatDateMDY, formattedTime } from "../../../utils";
import { queryClient } from "../../../apis/reactQuery";
import { useTranslation } from "react-i18next";
import PlacingBid from "../../common/PlacingBid";

function AuctionDetailsTab({
  auction,
  isUpcomingAuction,
  isActiveAuction,
  onPlaceBid,
  allowedBidPrice,
  highestAmount,
}) {
  const { FullDescription, Id, Price } = auction;
  const [activeTab, setActiveTab] = useState("description");
  const { t } = useTranslation();
  const [oldData, setOldData] = useState(null);
  const { data, isLoading } = useGetBidHistoryQuery(Id, oldData);
  const [highlight, setHighlight] = useState(false);
  const handleTabChange = tab => {
    setActiveTab(tab);
  };

  const handleRefreshHistory = () => {
    queryClient.invalidateQueries(["get_bids_history", Id]);
  };

  useEffect(() => {
    if (!isLoading && data?.data?.length !== oldData?.length) {
      setOldData(data?.data);
      setHighlight(true); // Apply highlight effect
      setTimeout(() => {
        setHighlight(false); // Remove highlight effect after 4 seconds
      }, 4000);
    }
  }, [data, isLoading, oldData]);

  if (isLoading) return <div>Loading...</div>;
  const { data: bidHistory } = data || [];
  return (
    <>
      <div className="row d-flex justify-content-center g-4">
        <div className="col-lg-8">
          <ul
            className="nav nav-pills d-flex flex-row justify-content-start gap-sm-4 gap-3 mb-45 wow fadeInDown"
            data-wow-duration="1.5s"
            data-wow-delay=".2s"
            id="pills-tab"
            role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link details-tab-btn ${activeTab === "description" ? "active" : ""}`}
                onClick={() => handleTabChange("description")}
                type="button">
                {t("description")}
              </button>
            </li>
            {!isUpcomingAuction && (
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link details-tab-btn ${activeTab === "bidding" ? "active" : ""} ${
                    highlight ? "lightning" : ""
                  }`}
                  onClick={() => handleTabChange("bidding")}
                  type="button">
                  {t("bidding_history")}
                </button>
              </li>
            )}
          </ul>
          {!isUpcomingAuction && (
            <div className="tab-content" id="pills-tabContent">
              <div
                className={`tab-pane fade ${activeTab === "description" ? "show active" : ""} wow fadeInUp`}
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
                id="pills-home"
                role="tabpanel">
                <div className="describe-content" dangerouslySetInnerHTML={{ __html: FullDescription }} />
              </div>
              <div
                className={`tab-pane fade ${activeTab === "bidding" ? "show active" : ""}`}
                id="pills-bid"
                role="tabpanel">
                {isActiveAuction && (
                  <button className="eg-btn btn--primary btn--sm fa fa-refresh" onClick={handleRefreshHistory}>
                    {t("refresh_list")}
                  </button>
                )}
                <div className="bid-list-area">
                  <ul className="bid-list">
                    {!bidHistory.length ? (
                      <div className="py-2">{t("no_bids")}</div>
                    ) : (
                      bidHistory?.map(({ Bid, Customer }, index) => (
                        <li key={Bid.Id}>
                          <div className="row d-flex align-items-center">
                            <div className="col-7">
                              <div className="bidder-area">
                                <div className="bidder-img">
                                  <img
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      borderRadius: "50%",
                                    }}
                                    alt="images"
                                    src={
                                      Customer?.ProfilePicture
                                        ? `${process.env.REACT_APP_API_URL}${Customer?.ProfilePicture}`
                                        : `${process.env.PUBLIC_URL}/images/logo-64.png`
                                    }
                                  />
                                </div>
                                <div className="bidder-content">
                                  <h6>{Customer?.FirstName + " " + Customer?.LastName}</h6>
                                  <p className={`${highlight && index === 0 ? "lightning" : ""}`}>
                                    {t("bid_amount")} {(Bid?.Amount || 0) + Price}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-5 text-end">
                              <div className="bid-time">
                                <p>{formatDateMDY(Bid.Date)}</p>
                                <p>{formattedTime(Bid.Date)}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-lg-4">
          <div className="bid-form">
            <div className="form-title">
              <h5>{t("bid_now")}</h5>
              <p className={highlight ? "lightning" : ""}>
                {t("amount")} : {t("egp")}
                {Price + highestAmount + allowedBidPrice}
              </p>
            </div>
            {/* <div className="form-inner gap-2">
              <button className="eg-btn btn--primary btn--sm" onClick={onPlaceBid}>
                {t("place_bid")}
              </button>
            </div> */}
            <PlacingBid onPlaceBid={onPlaceBid} />
          </div>
        </div>
      </div>
    </>
  );
}

export default AuctionDetailsTab;
