export const AR = "ar";
export const EN = "en";

export const REQUEST_LANG = {
  ar: "ar-EG",
  en: "en-US",
};

export const AuctionTypes = {
  LIVE: "current",
  ENDED: "ended",
  UPCOMING: "upcoming",
};
