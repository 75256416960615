import React, { useState } from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SignUpWrap from "./SignUpWrap";
import WelcomeScreen from "./WelcomeScreen";

function SignUp() {
  const [isRegistered, setIsRegistered] = useState(false);
  const handleRegistering = () => {
    setIsRegistered(true);
  };
  return isRegistered ? (
    <WelcomeScreen />
  ) : (
    <>
      <Breadcrumb pageName="Sign Up" pageTitle="Sign Up" />
      <SignUpWrap onRegister={handleRegistering} />
    </>
  );
}

export default SignUp;
