import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useLoginMutation } from "../../../services/user";
import { loginSchema } from "../../../services/schemas";

const LoginWrap = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { mutate: submitLogin, isLoading } = useLoginMutation();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const handleTogglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  const onSubmit = async (data) => {
    submitLogin(data);
  };

  return (
    <div className="login-section pt-120 pb-120">
      <img
        alt="images"
        src="assets/images/bg/section-bg.png"
        className="img-fluid section-bg-top"
      />
      <img
        alt="images"
        src="assets/images/bg/section-bg.png"
        className="img-fluid section-bg-bottom"
      />
      <div className="container">
        <div className="row d-flex justify-content-center g-4">
          <div className="col-xl-6 col-lg-8 col-md-10">
            <div
              className="form-wrapper wow fadeInUp"
              data-wow-duration="1.5s"
              data-wow-delay=".2s"
            >
              <div className="form-title">
                <h3>{t("login")}</h3>
                <p>
                  {t("new_member")}?{" "}
                  <Link
                    to={`${process.env.PUBLIC_URL}/signup`}
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    {t("signup")} {t("here")}
                  </Link>
                </p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-12">
                    <div className="form-inner">
                      <label htmlFor="email-input">{t("user_name")} *</label>
                      <input
                        id="username-input"
                        placeholder={t("user_name")}
                        {...register("username")}
                      />
                      {errors.username && (
                        <p className="error-message">
                          {t(errors.username.message)}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-inner">
                      <label htmlFor="password-input">{t("password")} *</label>
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password-input"
                        name="password"
                        placeholder={t("password")}
                        {...register("password")}
                      />
                      {errors.password && (
                        <p className="error-message">
                          {t(errors.password.message)}
                        </p>
                      )}
                      <i
                        className={
                          showPassword ? "bi bi-eye" : "bi bi-eye-slash"
                        }
                        id="togglePassword"
                        onClick={handleTogglePassword}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-agreement form-inner d-flex justify-content-between flex-wrap">
                      <div className="form-group"></div>
                      <Link to={"#"} className="forgot-pass">
                        {t("forget_password")}
                      </Link>
                    </div>
                  </div>
                </div>
                <button
                  disabled={isLoading}
                  className="account-btn"
                  type="submit"
                >
                  {isLoading ? <span>{t("Loading")}...</span> : t("login")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginWrap;
