import React from "react";

function DashboardWrap({ children }) {
  return (
    <>
      <div className="dashboard-section pt-120 pb-120">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-top"
        />
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-bottom"
        />
        <div className="container">
          <div className="row g-4">{children}</div>
        </div>
      </div>
    </>
  );
}

export default DashboardWrap;
