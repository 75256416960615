import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectFade, Pagination } from "swiper";
import { useTranslation } from "react-i18next";
import "swiper/css/autoplay";

SwiperCore.use([Pagination, Autoplay, EffectFade]);
const bannerSlider = {
  slidesPerView: 1,
  speed: 2500,
  loop: true,
  spaceBetween: 10,
  centeredSlides: true,
  roundLengths: true,
  parallax: true,
  effect: "fade",
  navigation: false,
  fadeEffect: {
    crossFade: true,
  },
  // navigation: {
  //   nextEl: '.hero-next3',
  //   prevEl: '.hero-prev3',
  // },
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  pagination: {
    el: ".hero-one-pagination",
    clickable: true,
    // renderBullet: function(index, className) {
    //   return '<span class="' + className + '">' +  0  + (index + 1) + "</span>";
    // }
  },
};

function HeroBanner() {
  const { t } = useTranslation();
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <>
      <div className="hero-area hero-style-one">
        <div className="hero-main-wrapper position-relative">
          <Swiper {...bannerSlider} className="swiper banner1">
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="slider-bg-1">
                  <div className="container">
                    <div className="row d-flex justify-content-center align-items-center">
                      <div className="col-xl-8 col-lg-10">
                        <div className="banner1-content">
                          <span>{t("welcome_to")}</span>
                          <h1>{t("home_banner_headline1")}</h1>
                          <p>{t("hame_banner_summarize1")}</p>
                          <Link
                            to={`${process.env.PUBLIC_URL}/auctions/current`}
                            onClick={scrollTop}
                            className="eg-btn btn--primary btn--lg"
                          >
                            {t("start_explore")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="slider-bg-2">
                  <div className="container">
                    <div className="row d-flex justify-content-center align-items-center">
                      <div className="col-xl-8 col-lg-10">
                        <div className="banner1-content">
                          <span>{t("welcome_to")}</span>
                          <h2>{t("home_banner_headline2")}</h2>
                          <p>{t("hame_banner_summarize2")}</p>
                          <Link
                            to={`${process.env.PUBLIC_URL}/auctions/current`}
                            className="eg-btn btn--primary btn--lg"
                          >
                            {t("start_explore")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
          <div className="hero-one-pagination d-flex justify-content-center flex-column align-items-center gap-3" />
        </div>
      </div>
    </>
  );
}

export default HeroBanner;
